function url() {
  const { NODE_ENV } = process.env;
  let admin_url = "";
  switch (NODE_ENV) {
    case "development":
      admin_url = "https://dg-web-dev.shouyinongye.com/console/login";
      break;
    case "uat":
      admin_url = "https://dg-web-uat.shouyinongye.com/console/login";
      break;
    default:
      admin_url = "https://shouyinongye.com/console/login";
      break;
  }
  return admin_url;
}
export const menu_list = [
  {
    title: "8455线路检测中心",
    ref: "home",
  },
  {
    title: "关于我们",
    ref: "about",
  },
  {
    title: "企业风采",
    ref: "elegant",
  },
  {
    title: "农业服务",
    ref: "agriculture",
  },
  {
    title: "企业荣誉",
    ref: "awards",
  },
  {
    title: "新闻资讯",
    ref: "news",
  },
  {
    title: "商务合作",
    ref: "cooperation",
  },
  {
    title: "联系我们",
    ref: "contact",
  },
  {
    title: "平台登录",
    href: url(),
  },
];

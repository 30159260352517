
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/news-list",
    name: "news-list",
    component: () => import(`@/views/news-list.vue`),
  },
  {
    path: "/news-detail",
    name: "news-detail",
    component: () => import(`@/views/news-detail.vue`),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes,
});

export default router;

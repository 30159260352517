<template>
<div class="com-footer">
<div class="link-container">
<div class="left">
<div>
<div class="title">农业服务</div>
<ul class="navigations">
<li><a href="/">耕种作业</a></li>
<li><a href="/">洒药作业</a></li>
<li><a href="/">播种作业</a></li>
<li><a href="/">收割作业</a></li>
<li><a href="/">春种作业</a></li>
</ul>
</div>
<div>
<div class="title">服务支持</div>
<ul class="navigations">
<li><a href="/">机手操作</a></li>
<li><a href="/">代工监管</a></li>
<li><a href="/">智慧通支持</a></li>
</ul>
</div>
<div>
<div class="title">关于我们</div>
<ul class="navigations">
<li><a href="/">8455线路检测中心</a></li>
<li><a href="/">社会回馈</a></li>
</ul>
</div>
</div>
<div class="right">
<div class="phone">{{ mobile }}</div>
<div class="time">周一至周日 9:00-12:00 13:30-18:00</div>
<div class="icon-line">
<div class="icon-item">
<img :src="img.sph" alt="wx" />
<p>视频号</p>
</div>
<div class="icon-item">
<img :src="img.gzh" alt="wx" />
<p>公众号</p>
</div>
<div class="icon-item">
<img src="@/assets/images/qywx.png" alt="wx" />
<p>企业微信</p>
</div>
</div>
</div>
</div>
<div class="footer-about">
<div class="about-info">
<span class="logo">
<img :src="img.logoFt" alt="logo" />
</span>
<ul>
<li
            v-for="(item, index) in menu_list"
            :key="index"
            @click="clickMenu(item, index)"
          >
            {{ item.title }}
          </li>
</ul>
</div>
</div>
<div class="footer-bottom">
<div>
        Copyright © 2016 - {{ now_year }} 8455线路检测中心 保留所有权利。(<a
          href="https://beian.miit.gov.cn"
          target="blank"
          >豫ICP备2021010325号-1</a
        >)&nbsp;&nbsp;&nbsp;
</div>
<div class="link">
        友情链接:
        <a href="http://www.tpwlw.com/" target="_blank">智慧农业</a>
<a href="https://www.nongjitong.com" target="_blank">农机通网</a>
<a href="http://www.zhibao17.com/" target="_blank">植保机械</a>
</div>
</div>
</div>
</template>
<script>
import { menu_list } from "@/constants/menu.js";
export default {
  props: {
    mobile: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      now_year: new Date().getFullYear(),
      menu_list,
      img: {
        logoFt:
          "https://mp-resource.shouyinongye.com/resource/website/logo-ft.png",
        sph: "https://mp-resource.shouyinongye.com/resource/website/sph.png", //视频号二维码,
        gzh: "https://mp-resource.shouyinongye.com/resource/website/gzh.png", //公众号,
        qywx: "", //企业微信,
      },
    };
  },
  methods: {
    clickMenu(item, index) {
      if (item.href) {
        window.open(item.href, "_blank");
      } else {
        this.$emit("clickMenu", { item, index });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.com-footer {
  margin-top: 80px;
  padding-top: 56px;
  padding-bottom: 76px;
  background: #286141;
  & > div {
    width: 1200px;
    margin: 0 auto;
  }
  .link-container {
    overflow: hidden;
    .left {
      float: left;
      & > div {
        float: left;
        & + div {
          margin-left: 150px;
        }
      }
      .title {
        height: 25px;
        font-size: 18px;
        font-weight: 500;
        color: #ffffff;
        line-height: 25px;
        margin-bottom: 40px;
      }
      ul {
        padding: 0;
        li {
          margin-bottom: 20px;
          text-align: left;
          a {
            height: 22px;
            font-size: 16px;
            color: #b3c7bc;
            line-height: 22px;
          }
        }
      }
    }
    .right {
      float: right;
      text-align: right;
      color: #b3c7bc;
      .phone {
        font-size: 28px;
        line-height: 38px;
        font-family: Bebas;
        font-weight: bold;
      }
      .time {
        font-size: 18px;
        line-height: 24px;
        margin: 16px 0 20px 0;
      }
      .icon-line {
        .icon-item {
          float: left;
          margin-left: 26px;
          img {
            width: 126px;
            height: 126px;
          }
          p {
            font-size: 12px;
            line-height: 24px;
            text-align: center;
            color: #b3c7bc;
          }
        }
      }
    }
  }
  .footer-about {
    margin-top: 90px;
    border-bottom: 1px solid #a9c0b3;
    height: 40px;
    .about-info {
      .logo {
        float: left;
        margin-right: 30px;
      }
      ul {
        float: left;
        li {
          display: inline-block;
          padding: 0 16px;
          position: relative;
          font-size: 16px;
          height: 22px;
          line-height: 22px;
          color: #ffffff;
          cursor: pointer;
          &:last-child {
            &::after {
              display: none;
            }
          }
          &::after {
            content: "";
            display: block;
            border-right: 1px solid #a9c0b3;
            height: 14px;
            position: absolute;
            top: 3px;
            right: 0;
          }
        }
      }
      .language {
        float: right;
        color: #fff;
        font-size: 16px;
        & img,
        & div {
          float: right;
        }
        img {
          margin-left: 10px;
        }
      }
    }
  }
  .footer-bottom {
    margin-top: 20px;
    & > div {
      float: left;
      font-size: 14px;
      color: #b3c7bc;
      a {
        color: inherit;
      }
    }
    .link {
      a {
        margin-left: 4px;
      }
    }
  }
}
</style>

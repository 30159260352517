<template>
<div class="company-map">
<div class="map-container" ref="map-container"></div>
</div>
</template>
<script>
export default {
  name: "company-map",
  props: {
    latitude: {
      type: [Number, String],
      default: "",
    },
    longitude: {
      type: [Number, String],
      default: "",
    },
    address: {
      type: String,
      default: "河南8455线路检测中心股份有限公司",
    },
  },
  data() {
    return {
      map: null,
      marker: require("@/assets/images/marker.png"),
    };
  },
  mounted() {
    if (!this.map) {
      this.initMap();
    }
  },
  unmounted() {
    if (this.map) {
      this.map.destroy();
    }
  },
  methods: {
    // 初始化地图
    initMap() {
      const center = new window.TMap.LatLng(
        this.latitude || 32.632111,
        this.longitude || 112.203261
      ); // 设置中心点坐标
      const el = this.$refs["map-container"];
      const map = new window.TMap.Map(el, {
        center,
        zoom: 17, // 设置地图缩放级别
        draggable: true, // 设置是否可以拖拽
        scrollwheel: true,
        disableDoubleClickZoom: true, // 设置是否可以双击放大
      });
      new window.TMap.InfoWindow({
        map: map,
        position: center, //设置信息框位置
        content: `<div class='info-window'>${this.address}</div>`, //设置信息框内容
        enableCustom: true,
        offset: { x: -20, y: -50 },
      });
      map.controlManager.controlContainer.hidden = true;
      new window.TMap.MultiMarker({
        map,
        styles: {
          styleId: new window.TMap.MarkerStyle({
            width: 40,
            height: 40,
            src: this.marker,
            anchor: { x: 40, y: 40 },
          }),
        },
        // 点标记数据数组
        geometries: [
          {
            id: "1", // 点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            styleId: "styleId", // 指定样式id
            position: center, // 点标记坐标位置
            properties: { title: "marker" },
          },
        ],
      });
      this.map = map;
    },
  },
};
</script>
<style lang="scss" scoped>
.company-map {
  width: 100%;
  height: 100%;
  margin-top: 100px;
  position: relative;
  .map-container {
    max-width: 1200px;
    height: 460px;
    margin: 0 auto;
    ::v-deep .info-window {
      padding: 10px 20px;
      background: #286141;
      color: #fff;
      border-radius: 6px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        bottom: -9px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #286141;
      }
    }
  }
}
</style>
